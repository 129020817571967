export enum EntityPageSection {
  Home = 'home',
  Dashboard = 'dashboard',
  Settings = 'settings',
  About = 'about',
  Collaboration = 'collaboration',
  Community = 'community',
  Contribute = 'contribute',
  KnowledgeBase = 'knowledge-base',
  Subspaces = 'subspaces',
  Subsubspaces = 'subsubspaces',
  Agreements = 'agreements',
  Profile = 'profile',
  Share = 'share',
  Search = 'search',
}
